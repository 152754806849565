import React, { useEffect } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IContentValue } from "types";
import { Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";

interface IProps {
  value: IContentValue;
  control: any;
  contentIndex: number;
}

const TextComponent: React.FC<IProps> = ({ value, control, contentIndex }) => {
  // console.log("TextComponent", value);

  return (
    <div>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">title h2</InputGroup.Text>
        <Controller
          control={control}
          name={`content.${contentIndex}.title`}
          render={({
            field: { onChange, ref, value },
            fieldState,
            formState,
          }) => (
            <Form.Control
              ref={ref}
              value={value}
              onChange={onChange}
              placeholder="if necessary"
              aria-label="title"
            />
          )}
        />
      </InputGroup>
      <Controller
        control={control}
        name={`content.${contentIndex}.text`}
        render={({
          field: { onChange, ref, value },
          fieldState,
          formState,
        }) => (
          <CKEditor
            ref={ref}
            data={value}
            editor={ClassicEditor}
            onChange={(event: any, editor: any) => onChange(editor.getData())}
          />
        )}
      />
    </div>
  );
};

export default TextComponent;

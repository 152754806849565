import React, { useState } from 'react';
import { Col, Row, FloatingLabel, Form, Button, Modal } from 'react-bootstrap';
import { useRemoveGeoMutation, useUpdateGeoMutation } from 'store/api/data.api';
import { Country, GeoType, Language } from 'types';

const GeoSettings = ({ geo, languages, countries }: { geo: GeoType, languages: Language[], countries: Country[] }) => {
    const [newLang, setNewLang] = useState<number>(geo.languageId);
    const [newCountry, setNewCountry] = useState<number>(geo.countryId);
    const [modifedSettings, setModifedSettings] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [removeGeo] = useRemoveGeoMutation();
    const [updateGeo] = useUpdateGeoMutation();

    const handleUpdate = () => { 
        updateGeo({ ...geo, languageId: newLang, countryId: newCountry })
        setModifedSettings(false);

    };
    const handleRemove = () => {
        removeGeo(geo.id)
    }


    return (
        <div className='geo-settings'>
            <Row >
                <Col sm={4}>
                    <FloatingLabel controlId="floatingSelect" label="lang:">
                        <Form.Select aria-label="Floating label select example" 
                            defaultValue={languages!.find(lang => lang.id === geo.languageId)!.id}
                            onChange={(e) => { setNewLang(Number(e.target.value)); setModifedSettings(true); }}
                        >
                            <option value="-1">none</option>
                            {
                                languages.map(lang => {
                                return <option value={lang.id} key={lang.id}>{lang.name}</option>
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={4}>
                    <FloatingLabel controlId="floatingSelect" label="country:">
                        <Form.Select aria-label="Floating label select example" 
                            defaultValue={countries.find(country => country.id === geo.countryId)!.id} 
                            onChange={(e) => { setNewCountry(Number(e.target.value)); setModifedSettings(true); }}
                        >
                            <option value="-1">none</option>
                            {
                                countries.map(country => {
                                return <option value={country.id} key={country.id}>{country.name}</option>
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={4} className='text-end'>
                    <Button className='me-1' variant="primary" onClick={handleUpdate} disabled={!modifedSettings}>save</Button>
                    <Button className='me-1' variant='danger' onClick={() => setConfirm(true)}>delete geo</Button>
                </Col>
            </Row>
            <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this geo?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setConfirm(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleRemove}>
                    Remove geo
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GeoSettings;
import SettingsIcon from "assets/images/icons/SettingsIcon";

import React, { useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";

import { useRemovePageMutation } from "store/api/data.api";
import PageNameForm from "components/forms/project/PageNameForm";

import { TypePage } from "types";
import KeysCluster from "components/sortElements/keyClasterElements/KeysCluster";
import { Link, useParams } from "react-router-dom";
import XLParser from "./keyClasterElements/XLParser";
import AddKeywordComponent from "components/sortElements/keyClasterElements/AddKeywordComponent";

const massages = [
  "Are you sure you want to delete this page? It will remove all child pages",
  "Are you sure you want to delete this page?",
];

const Page = ({ page }: { page: TypePage }) => {
  const { id: projectId } = useParams();
  const [confirm, setConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [removePage] = useRemovePageMutation();

  const handleRemove = () => {
    setConfirm(false);
    removePage({ pageId: page.pageId, projectId });
  };

  return (
    <>
      <div className="text-end mb-2">
        <ButtonGroup>
          <Button
            variant="light"
            onClick={() => setShowForm(true)}
            className="btn-sm"
          >
            <SettingsIcon iconSize={10} />
          </Button>
          <Button
            variant="danger"
            onClick={() => setConfirm(true)}
            className="btn-sm"
            title="Remove page"
          >
            -
          </Button>
        </ButtonGroup>
      </div>

      <KeysCluster page={page} />
      <AddKeywordComponent page_pageId={page.pageId} />
      <XLParser page={page} />

      <div className="text-center mb-2">
        <Button
          variant="light"
          onClick={() => setShowForm(true)}
          className="btn-sm"
        >
          Check occurence
        </Button>
        <Link
          to={`/analytics/${projectId}/content/${page.geoId}/${page.pageId}`}
          className="btn btn-primary btn-sm"
        >
          to Content
        </Link>
      </div>

      <Modal show={confirm} onHide={() => setConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {page.pageType === 0 ? massages[0] : massages[1]}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirm(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRemove}>
            Remove page
          </Button>
        </Modal.Footer>
      </Modal>
      {showForm && (
        <PageNameForm
          showForm={showForm}
          setShowForm={setShowForm}
          parentPageId={page.parentPageId}
          geoId={page.geoId}
          page={page}
        />
      )}
    </>
  );
};

export default Page;

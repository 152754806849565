import React from 'react';

import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { useAddProjectMutation, useUpdateProjectMutation } from 'store/api/data.api';

import { ProjectFormProps } from 'types';

const ProjectForm = ({smShow, setSmShow, project}:ProjectFormProps) => {

    const [addProject] = useAddProjectMutation();
    const [updateProject] = useUpdateProjectMutation();

    const handleForm = (event:any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        project ? updateProject({...project, newName: event.target[0].value}) : addProject(event.target[0].value);
        setSmShow(false);
    };
    return (
        <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
                    {project?.name ? 'Edit' : 'Add'} Project
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form noValidate validated={false} onSubmit={handleForm}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Enter project name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="new"
                        defaultValue={project?.name ? project.name : ''}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Button type="submit" className='float-end'>Submit form</Button>
            </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ProjectForm;
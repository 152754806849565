import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import HrefModal from './HrefModal';

const ClusterSettings = () => {
    const [showAhrefs, setShowAhrefs] = useState(false)
    
    return (
        <div>
                    <ButtonGroup className=''>
                        <Button className='btn-success btn-sm' onClick={() => setShowAhrefs(true)}>Hrefs</Button>
                    </ButtonGroup>
                    {showAhrefs && <HrefModal setShowForm={setShowAhrefs} showForm={showAhrefs} />}
        </div>
    );
};

export default ClusterSettings;
import React from 'react';

const CopyIcon = ({iconSize=15}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  fill="#fff" height={iconSize} width={iconSize} version="1.1"  viewBox="0 0 22 22">
            <g id="Double_down" data-name="Double down">
                <path d="M12,17a1,1,0,0,1-.707-.293l-4-4a1,1,0,1,1,1.414-1.414L12,14.586l3.293-3.293a1,1,0,0,1,1.414,1.414l-4,4A1,1,0,0,1,12,17Z" />
                <path d="M12,13a1,1,0,0,1-.707-.293l-4-4A1,1,0,1,1,8.707,7.293L12,10.586l3.293-3.293a1,1,0,1,1,1.414,1.414l-4,4A1,1,0,0,1,12,13Z" />
            </g>
        </svg>
    );
};

export default CopyIcon;
import { bindActionCreators } from "@reduxjs/toolkit"; 
import { useDispatch } from "react-redux";
import { sortPageActions } from "store/sortPage/sortPage.slice"; 


const actions = {
    ...sortPageActions,
}

export const useSortPageActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(actions, dispatch);

}
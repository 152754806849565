import React, { useEffect } from 'react';
import { Accordion, Button} from 'react-bootstrap';

import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {useGetProjectsQuery} from 'store/api/data.api';
import { Project } from 'types';

import 'assets/styles/analytics-layout.scss';

import { ProjectMenu } from './ProjectMenu'
import ProjectForm from 'components/forms/project/ProjectForm';
import OpenMenuArrow from 'assets/images/icons/OpenMenuArrow';
import { useAppSelector } from 'hooks/redux';
import { useAnaliticsLayoutActions } from 'hooks/analyticsLayotActions';
import { useAuthQuery } from 'store/auth/auth.api';
import { useDispatch } from 'react-redux';
import { useAuthActions } from 'hooks/authActions'; 
import { LOGIN_ROUTE } from 'utils/consts';




const LeftMenu = () => {
    const [smShow, setSmShow] = useState(false);
    const [showProjects, setShowProjects] = useState(true);

    const { data:projects } = useGetProjectsQuery()


    const {openedProjects} = useAppSelector(state => state.analyticsLayout)
    const {setOpenedProjects} = useAnaliticsLayoutActions()

    const { data, isLoading, isError, isSuccess } = useAuthQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setCredentials } = useAuthActions();
    const { user, token } = useAppSelector(state => state.auth);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCredentials({...data}))
        }
    }, [data])

    if (isLoading) {
        return <p>Loading...</p>;
    }
    if (isError) {
        navigate(LOGIN_ROUTE);
    }


    if(user.isAuth) {
        return (
            <div className='row analytics-layout'>
                <div className={'col-sm-2 analytics-layout-left-menu'.concat(showProjects ? '' : ' hide')}>
                    <Accordion defaultActiveKey={[]} activeKey={openedProjects} alwaysOpen onSelect={(activeKey) => setOpenedProjects((activeKey && typeof activeKey === 'string') ? [activeKey] : activeKey)}>
                        {
                            projects?.map((project: Project) => {
                                return (
                                    <Accordion.Item eventKey={project.id} key={project.id} >
                                            <ProjectMenu
                                                project={project}
                                            />
                                            <Accordion.Header>{project.name}</Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    <li><Link to={`/analytics/${project.id}/overview` }>overview</Link></li>
                                                    <li><Link to={`/analytics/${project.id}/sort-page` }>sort</Link></li>
                                                    <li><Link to={`/analytics/${project.id}/organic` }>organic</Link></li>
                                                    <li><Link to={`/analytics/${project.id}/google-ads`}>google ads</Link></li>
                                                    <li><Link to={`/analytics/${project.id}/google-analytics` }>analytics</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                    <div className='text-start'>
                        <Button onClick={() => setSmShow(true)} className="me-2 mt-3 me-2 ">add</Button>
                    </div>
    
                </div>
                <div className={"analytics-layout-workspace col-sm-".concat(showProjects ? '10' : '12')}>
                    <Outlet />
                    <button type='button' onClick={() => setShowProjects(!showProjects)} className={'hide-btn'.concat(showProjects ? ' active' : '')}><OpenMenuArrow iconSize={20}/></button>
                </div>
                <ProjectForm
                    smShow={smShow}
                    setSmShow={setSmShow}            
                />
            </div>
        );
    } else {
        navigate(LOGIN_ROUTE);
    }

    return null
   
};



export default LeftMenu;



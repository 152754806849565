import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const SELECTED_GEO = "selectedGeo";

const initialState: any = {
  [SELECTED_GEO]: JSON.parse(localStorage.getItem(SELECTED_GEO) ?? "{}"),
  currentProject: JSON.parse(localStorage.getItem("currentProject") ?? "{}"),
  copyState: JSON.parse(localStorage.getItem("copyState") ?? "false"),
  cutState: JSON.parse(localStorage.getItem("cutState") ?? "false"),
  keywordsIds: JSON.parse(localStorage.getItem("keywordsIds") ?? "[]"),
};

export const sortPage = createSlice({
  name: "sortPage",
  initialState,
  reducers: {
    setSelectedGeo(state, action: PayloadAction<any>) {
      state[SELECTED_GEO] = { ...state[SELECTED_GEO], ...action.payload };
      localStorage.setItem(SELECTED_GEO, JSON.stringify(state[SELECTED_GEO]));
    },
    setCurrentProject(state, action: PayloadAction<any>) {
      state.currentProject = { ...state.currentProject, ...action.payload };
      localStorage.setItem(
        "currentProject",
        JSON.stringify(state.currentProject)
      );
    },
    setCopyState(state, action: PayloadAction<boolean>) {
      state.copyState = action.payload;
      localStorage.setItem("copyState", JSON.stringify(state.copyState));
    },
    setCutState(state, action: PayloadAction<boolean>) {
      state.cutState = action.payload;
      localStorage.setItem("cutState", JSON.stringify(state.cutState));
    },
    setKeywordsIds(state, action: PayloadAction<number[]>) {
      state.keywordsIds = [...action.payload];
      localStorage.setItem("keywordsIds", JSON.stringify(state.keywordsIds));
    },
  },
});

export const sortPageActions = sortPage.actions;
export const sortPageReducer = sortPage.reducer;

import React, { useState } from 'react';

import {useRemoveProjectMutation} from 'store/api/data.api';
import Dropdown from 'react-bootstrap/Dropdown';
import ProjectForm from 'components/forms/project/ProjectForm';
import SettingsIcon from 'assets/images/icons/SettingsIcon';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export const ProjectMenu = ({project}:any) => {
    const [smShow, setSmShow] = React.useState(false);
    const [removeProject] = useRemoveProjectMutation();
    const [confirm, setConfirm] = useState(false);
    const navigate = useNavigate();

    const handleRemove = () => {
        removeProject(project.id);
        navigate('/analytics');
    }

    return (
        <>
            <Dropdown className='ms-1'>
                <Dropdown.Toggle variant="Light" id="dropdown-basic">
                    <SettingsIcon iconSize={15} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSmShow(state => !state)}>Rename</Dropdown.Item>
                    <Dropdown.Item onClick={() => setConfirm(true) }>Remove</Dropdown.Item>
                </Dropdown.Menu>
                <ProjectForm
                    smShow={smShow}
                    setSmShow={setSmShow}
                    project={project}        
                />
            </Dropdown>           
            <Modal show={confirm} onHide={() => setConfirm(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this project?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setConfirm(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleRemove}>
                    Remove project
                </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default ProjectMenu;

import { useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useAddKeywordMutation } from "store/api/data.api";
import { KeyWord } from "types";

const newKeywordInit: KeyWord = {
  name: "",
  id: 0,
  projectId: "",
  geoId: "",
  languageId: 0,
  countryId: 0,
  page_pageId: 0,
};

interface KeywordProps {
  page_pageId: number;
}

const AddKeywordComponent: React.FC<KeywordProps> = ({ page_pageId }) => {
  const [addKeyword] = useAddKeywordMutation();
  const { selectedGeo, currentProject: project } = useAppSelector(
    (state) => state.sortPage
  );

  const [newKeyword, setNewKeyword] = useState({
    ...newKeywordInit,
    projectId: project.id,
    geoId: selectedGeo[project.id].id,
    languageId: selectedGeo[project.id].languageId,
    countryId: selectedGeo[project.id].countryId,
    page_pageId: page_pageId,
  });

  const handleAddKeyword = () => {
    console.log(newKeyword);
    addKeyword(newKeyword)
      .unwrap()
      .then((res) => setNewKeyword({ ...newKeyword, name: "" }))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Form>
        <table className="table table-striped table-bordered ">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Volume</th>
                                <th>KD</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={newKeyword.name}
                  onChange={(e) =>
                    setNewKeyword({ ...newKeyword, name: e.target.value })
                  }
                />
              </td>
              {/* <td><input type="number" className="form-control" name='volume' onChange={(e) => setNewKeyword({ ...newKeyword, volume: Number(e.target.value) })} /></td>
                                <td><input type="number" className="form-control" name='kd' onChange={(e) => setNewKeyword({ ...newKeyword, kd: Number(e.target.value)  })} /></td> */}
            </tr>
          </tbody>
        </table>
        <ButtonGroup className="d-grid justify-content-end">
          <Button
            type="button"
            className="btn-primary btn-sm"
            onClick={handleAddKeyword}
          >
            Add
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

export default AddKeywordComponent;

import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';


interface IProps {
    setShowForm:any,
    showForm:any,
}

const HrefModal:React.FC<IProps> = ({setShowForm, showForm }) => {
    
    return (
        <Modal
        size="xl"
        show={showForm}
        onHide={() => setShowForm(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm text-center">
                    Hrefs
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form noValidate validated={false} >

                <Button type="submit" className='float-end'>save</Button>
            </Form>
            </Modal.Body>
        </Modal>
    );
};

export default HrefModal;
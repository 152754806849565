import React, { useEffect } from "react";

import "assets/styles/project-header.scss";

import { useParams } from "react-router-dom";
import { useGetProjectQuery } from "store/api/data.api";
import ProjectHeaderSettings from "components/project/ProjectHeaderSettings";
import SettingsIcon from "assets/images/icons/SettingsIcon";
import { useSortPageActions } from "hooks/sortPageActions";

const ProjectHeader = () => {
  const [showSettings, setShowSettings] = React.useState(false);
  const { id = "" } = useParams();
  const { setCurrentProject } = useSortPageActions();
  const { data: project, isSuccess } = useGetProjectQuery(id);

  useEffect(() => {
    if (project) setCurrentProject(project);
  }, [isSuccess]);

  if (!id) {
    return null;
  }

  // console.log(project)

  return (
    <div className="project-header">
      <h3 className="project-header__title">{project?.name}</h3>
      <button
        className="settings-btn"
        onClick={() => setShowSettings(!showSettings)}
      >
        <SettingsIcon iconSize={20} />
      </button>
      {isSuccess && showSettings && <ProjectHeaderSettings project={project} />}
    </div>
  );
};

export default ProjectHeader;

import { useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { useAddKeywordsMutation } from "store/api/data.api";
import { TypePage } from "types";
import xlsx from "xlsx";

interface IXLParser {
  page: TypePage;
}

const fileTypes = ["xlsx", "xls", "csv"];

const XLParser: React.FC<IXLParser> = ({ page }) => {
  const { id: projectId } = useParams();
  const { selectedGeo } = useAppSelector((state) => state.sortPage);
  const [addKeywords] = useAddKeywordsMutation();

  const readUploadFile = (inputFile: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet, { range: 0 });
      addKeywords({
        keywords: json,
        page_pageId: page.pageId,
        projectId: selectedGeo[projectId!].projectId,
        geoId: selectedGeo[projectId!].id,
        languageId: selectedGeo[projectId!].languageId,
        countryId: selectedGeo[projectId!].countryId,
        source: "hrefs",
      });
    };
    reader.readAsArrayBuffer(inputFile);
  };
  return (
    <div className="my-3">
      <FileUploader
        handleChange={readUploadFile}
        name="file"
        types={fileTypes}
      />
    </div>
  );
};

export default XLParser;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const OPENED_PR = 'openedProjects'

const initialState: any  ={
    [OPENED_PR]: JSON.parse(localStorage.getItem(OPENED_PR) ?? '[]')
}



export const analyticsLayoutSlice = createSlice({
    name: 'analyticsLayout',
    initialState,
    reducers: {
        setOpenedProjects: (state, action: PayloadAction<any>) => {
            state[OPENED_PR] = action.payload
            localStorage.setItem(OPENED_PR, JSON.stringify(action.payload))
        },
    }
    })

export const analyticsLayoutActions = analyticsLayoutSlice.actions;
export const analyticsLayoutReducer = analyticsLayoutSlice.reducer;

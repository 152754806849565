export type Project = {
  id: string;
  name: string;
  projectSettings?: ProjectSettings | undefined;
};

export type ProjectSettings = {
  useChatGPT?: boolean;
  chatGPTApiKey?: string;
  useAhrefs?: boolean;
  ahrefsToken?: string;
  ahrefsApiKey?: string;
};

export type GeoType = {
  id: string;
  projectId: string;
  languageId: number;
  countryId: number;
  geoSettings: {};
  sortOrder: number;
};
export type Language = {
  id: number;
  code: string;
  name: string;
  nativeName: string;
};

export type Country = {
  id: number;
  name: string;
  Iso: string;
  code: string;
};

export type TypePage = {
  id: number;
  pageId: number;
  geoId: string;
  parentPageId: number;
  name: string;
  pageType: EnumPageType;
  sortOrder: number;
};

export enum EnumPageType {
  category,
  product,
  sevice,
  article,
}

export interface KeyWord {
  id: number;
  projectId: string;
  geoId: string;
  languageId: number;
  countryId: number;
  page_pageId: number;
  name: string;
}
export interface KeyWordState extends KeyWord {
  selected: boolean;
  [key: string]: any;
}

export interface IContent {
  id: number;
  projectId: string;
  geoId: string;
  page_pageId: number; //for geo tabs in content page
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  title: string;
  content: IContentValue[];
}

export interface IContentValue {
  id: string;
  contentType: string;
  title?: string;
  products?: number[];
  text?: string;
  order: number;
}

export type ProjectFormProps = {
  smShow: boolean;
  setSmShow: Function;
  project?: Project;
};

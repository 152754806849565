import React from 'react';

const ArticleIcon = ({iconSize=15}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize}  version="1.1" id="_x32_" viewBox="0 0 512 512" >
            <style type="text/css">
            </style>
            <g>
                <rect x="106.667" y="106.667"  width="298.666" height="106.666"/>
                <path  d="M0,0v512h512V0H0z M458.667,458.667H53.333V53.333h405.334V458.667z"/>
                <rect x="106.667" y="277.333"  width="298.666" height="32"/>
                <rect x="106.667" y="362.667" width="298.666" height="32"/>
            </g>
            </svg>
        </>
    );
};

export default ArticleIcon;
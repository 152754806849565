import { useAppSelector } from "hooks/redux";
import React, { ChangeEventHandler } from "react";
import { KeyWordState } from "types";

type KeyWordStateKeys = keyof KeyWordState;

type Props = {
  filtredKeywords: KeyWordState[];
  selectAllState: boolean;
  handleAllSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (e: React.FormEvent<HTMLInputElement>) => void;
  sortBy: (sort: KeyWordStateKeys) => void;
};

const KeywordsList = ({
  filtredKeywords,
  selectAllState,
  handleAllSelect,
  handleSelect,
  sortBy,
}: Props) => {
  const { currentProject: project } = useAppSelector((state) => state.sortPage);

  const totalValue = filtredKeywords.reduce((acc, keyword) => acc + keyword.volume, 0)
  const averegeKD = filtredKeywords.length > 0 ? Math.round(filtredKeywords.reduce((acc, keyword) => acc + keyword.kd, 0) / filtredKeywords.length) : 0
  // const averegeNumberOfOccurences = filtredKeywords.length > 0 ? (filtredKeywords.reduce((acc, keyword) => acc + keyword.numberOfOccurences, 0) / filtredKeywords.length).toFixed(2) : 0
  // const averegePercentOfOccurences = filtredKeywords.length > 0 ? Math.round(filtredKeywords.reduce((acc, keyword) => acc + keyword.percentOfOccurences, 0) / filtredKeywords.length) : 0

  return (
    <table className="table table-striped table-bordered cluster-table">
      <thead>
        <tr>
          <th colSpan={2}>keyword</th>
          {project.projectSettings?.googleStats && (
            <th colSpan={4} className="text-center">
              google stat
            </th>
          )}
        </tr>
        <tr>
          <th>
            <span className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectAllState}
                onChange={handleAllSelect}
              />
            </span>
          </th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("name")}
            >
              Name
            </span>
          </th>
          {project.projectSettings?.googleStats && (
            <>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("name")}
                >
                  volume
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("name")}
                >
                  comp. index
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("name")}
                >
                  bid low
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("name")}
                >
                  bid high
                </span>
              </th>
            </>
          )}
                    {project.projectSettings?.useAhrefs && (
            <>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("kd")}
                >
                  kd
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("volume")}
                >
                  volume
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("cpc")}
                >
                  cpc
                </span>
              </th>
              <th>
                <span
                  className="sort-btn"
                  title="sort"
                  onClick={() => sortBy("cps")}
                >
                  cps
                </span>
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {filtredKeywords.map((keyword) => {
          return (
            <tr key={keyword.id}>
              <td>
                <span className="form-check">
                  <input
                    className="form-check-input"
                    id={keyword.id.toString()}
                    type="checkbox"
                    checked={keyword.selected}
                    onChange={handleSelect}
                  />
                </span>
              </td>
              <td>{keyword.name}</td>
              {project.projectSettings?.googleStats && (
                <>
                  <td>{keyword.googleStats?.volume?.toString()}</td>
                  <td>{keyword.googleStats.competition_index?.toString()}</td>
                  <td>{keyword.googleStats.bid_low_range?.toString()}</td>
                  <td>{keyword.googleStats.bid_high_range?.toString()}</td>
                </>
              )}
              {project.projectSettings?.useAhrefs && (
                <>
                  <td>{keyword.kd?.toString()}</td>
                  <td>{keyword.volume?.toString()}</td>
                  <td>{keyword.cpc?.toString()}</td>
                  <td>{keyword.cps?.toString()}</td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <span className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectAllState}
                onChange={handleAllSelect}
              />
            </span>
          </td>
          <td>Total:</td>
          <td>{averegeKD}</td>
          <td>{totalValue}</td>
          
                            {/* <td>{averegeNumberOfOccurences}</td>
                            <td>{averegePercentOfOccurences}</td> */}
        </tr>
      </tfoot>
    </table>
  );
};

export default KeywordsList;

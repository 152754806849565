import React from 'react';

const CopyIcon = ({iconSize=15}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  fill="#fff" height={iconSize} width={iconSize} version="1.1"  viewBox="0 0 24 24">
            <g>
            <polygon points="13.707 4.707 12.293 3.293 3.586 12 12.293 20.707 13.707 19.293 6.414 12 13.707 4.707"/>
            <polygon points="19.707 4.707 18.293 3.293 9.586 12 18.293 20.707 19.707 19.293 12.414 12 19.707 4.707"/>
            </g>
        </svg>
    );
};

export default CopyIcon;
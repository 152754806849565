import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';


import { projectsApi } from "store/api/data.api";
import {analyticsLayoutReducer} from "./analyticsLayout/analyticsLayout.slice";
import { authApi } from "./auth/auth.api";
import { authReducer } from "./auth/auth.slice";
import { sortPageReducer } from "./sortPage/sortPage.slice";


export const store = configureStore( {
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        analyticsLayout: analyticsLayoutReducer,
        sortPage: sortPageReducer,

    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authApi.middleware, projectsApi.middleware),
        
})


setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>



import PageNameForm from 'components/forms/project/PageNameForm';
import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from 'react-bootstrap';
import HrefModal from './keyClasterElements/HrefModal';



const CollumnSettings = ({parentPageId, geoId, setSearchInput} : { parentPageId: number, geoId: string, setSearchInput:Function }) => {
    const [showForm, setShowForm] = useState(false)
    const [showAhrefs, setShowAhrefs] = useState(false)

    return (
        <Row className='my-3'>
            <Col sm={8}>
            <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id={'text-search' + parentPageId.toString()}>
                Search:
                </InputGroup.Text>
                <Form.Control id={'search' + parentPageId.toString()} onChange={(e) => setSearchInput(e.target.value)} />
            </InputGroup>
            </Col>
            <Col sm={4} className="text-end">
                <ButtonGroup className=''>
                    <Button className='btn-primary btn-sm' onClick={()=> setShowForm(true)}>Add</Button>
                    <Button className='btn-success btn-sm' onClick={()=> setShowAhrefs(true)}>Hrefs</Button>
                </ButtonGroup>
            </Col>
            {showForm && <PageNameForm showForm={showForm} setShowForm={setShowForm} parentPageId={parentPageId} geoId={geoId} />}
            {showAhrefs && <HrefModal setShowForm={setShowAhrefs} showForm={showAhrefs} />}
        </Row>
    );
};

export default CollumnSettings;
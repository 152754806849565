import ArticleIcon from "assets/images/icons/page/ArticleIcon";
import FolderIcon from "assets/images/icons/page/FolderIcon";
import ProductIcon from "assets/images/icons/page/ProductIcon";
import ServiceIcon from "assets/images/icons/page/ServiceIcon";

import CollumnSettings from "components/sortElements/CollumnSettings";
import Page from "components/sortElements/Page";
import { useAppSelector } from "hooks/redux";
import { useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import {
  useGetPagesQuery,
  useGetParentPageByIdAndGeoQuery,
} from "store/api/data.api";
import { TypePage } from "types";

const pagesIcon = [
  <FolderIcon />,
  <ProductIcon />,
  <ServiceIcon />,
  <ArticleIcon />,
];

interface ColumnProps {
  parentPageId: number;
  geoId: string;
  pathname: string;
  nastingLevels: string[];
}

const Column: React.FC<ColumnProps> = ({
  parentPageId,
  geoId,
  pathname,
  nastingLevels,
}) => {
  const { data: pages, refetch } = useGetPagesQuery({ geoId, parentPageId });
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const { data: parentPage, isLoading: isLoadingParentPage } =
    useGetParentPageByIdAndGeoQuery({ parentPageId, geoId });

  useEffect(() => {
    refetch();
  }, [refetch]);

  let fullLocation = pathname + "?";
  if (parentPageId !== 0 && nastingLevels.length > 0) {
    for (
      let i = 0;
      i < nastingLevels.indexOf(parentPageId.toString()) + 1;
      i++
    ) {
      fullLocation += "category=" + nastingLevels[i] + "&";
    }
  }

  const handleClickLink = (e: any, id: number, pageType: number) => {
    e.preventDefault();
    if (pageType === 0) {
      navigate(fullLocation + "category=" + id);
    }
  };

  const sortedPages = useMemo(() => {
    if (pages && pages.length > 0 && searchInput.length > 1) {
      return [...pages].filter((page) =>
        page.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
      );
    } else {
      return pages;
    }
  }, [pages, searchInput]);

  return (
    <div className="column">
      <h4 className="text-center">
        {isLoadingParentPage
          ? "Loading..."
          : parentPage && !isLoadingParentPage
          ? parentPage.name
          : "Main"}{" "}
      </h4>
      <CollumnSettings
        parentPageId={parentPageId}
        geoId={geoId}
        setSearchInput={setSearchInput}
      />
      <Accordion defaultActiveKey={[]} alwaysOpen>
        {sortedPages?.map((page: TypePage) => {
          return (
            <Accordion.Item eventKey={page.id.toString()} key={page.id}>
              <Accordion.Header
                className={
                  nastingLevels.includes(page.pageId.toString())
                    ? " active-category "
                    : ""
                }
              >
                <span className="accordion_page-type">
                  {pagesIcon[page.pageType]}
                </span>
                <span
                  onClick={(e) =>
                    handleClickLink(e, page.pageId, page.pageType)
                  }
                  className={"".concat(
                    page.pageType === 0 ? " accordion_page-url " : ""
                  )}
                >
                  {page.name}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Page page={page} />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default Column;

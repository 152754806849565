import React, { ChangeEventHandler, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { IContentValue, KeyWord } from "types";
import openai from "utils/OpenAIConfigure";
import prompts from "utils/prompts";

interface Props {
  cluster: KeyWord[];
  setValue: any;
  upadateArray: any;
}

interface IConversation {
  [key: string]: string;
}

const OpenAIContentHelper: React.FC<Props> = ({
  cluster,
  setValue,
  upadateArray,
}) => {
  const [prompt, setPrompt] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [isSuccessed, setIsSuccessed] = useState(false);
  const [conversation, setConversation] = useState<IConversation[]>([]);
  const keyString = cluster.map((item) => item.name).join(", ");

  const fields = ["meta_title", "meta_description", "title", "content"];

  const fillAuto = async () => {
    setIsSend(true);
    for (const field of fields) {
      setConversation((prev) => [
        ...prev,
        { you: prompts[field as keyof typeof prompts] + keyString },
      ]);
      const completion = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompts[field as keyof typeof prompts] + keyString,
      });
      setConversation((prev) => [
        ...prev,
        { gpt: completion.data.choices[0].text },
      ]);
      if (field === "content") {
        upadateArray(0, {
          title: "",
          contentType: "text",
          text: completion.data.choices[0].text,
        });
      } else {
        setValue(field, completion.data.choices[0].text);
      }
    }
    setIsSend(false);
  };

  const sendRequest = async () => {
    setIsSend(true);
    if (selectedField !== "") {
      try {
        setConversation((prev) => [...prev, { you: prompt }]);
        const completion = await openai.createCompletion({
          model: "text-davinci-003",
          max_tokens: 2000,
          prompt: prompt,
        });
        setConversation((prev) => [
          ...prev,
          { gpt: completion.data.choices[0].text },
        ]);
        if (selectedField === "content") {
          upadateArray(0, {
            contentType: "text",
            text: completion.data.choices[0].text,
          });
        } else {
          setValue(selectedField, completion.data.choices[0].text);
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        } else {
          console.log(error.message);
        }
      }
    }

    setIsSend(false);
  };

  return (
    <>
      <div>
        <h3 className="text-center mt-3">OpenAI Content Helper</h3>
        <ButtonGroup className="mb-3">
          <Button variant="success" onClick={fillAuto} disabled={isSend}>
            Fill all fields
          </Button>
        </ButtonGroup>
        <ButtonGroup className="mb-3 d-flex">
          <Button
            active={selectedField === "meta_title"}
            onClick={() => {
              setPrompt(prompts.meta_title + keyString);
              setSelectedField("meta_title");
            }}
          >
            Meta title
          </Button>
          <Button
            active={selectedField === "meta_description"}
            onClick={() => {
              setPrompt(prompts.meta_description + keyString);
              setSelectedField("meta_description");
            }}
          >
            Meta description
          </Button>
          <Button
            active={selectedField === "title"}
            onClick={() => {
              setPrompt(prompts.title + keyString);
              setSelectedField("title");
            }}
          >
            Page title
          </Button>
          <Button
            active={selectedField === "content"}
            onClick={() => {
              setPrompt(prompts.content + keyString);
              setSelectedField("content");
            }}
          >
            Page description
          </Button>
        </ButtonGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Prompt</InputGroup.Text>
          <Form.Control
            as="textarea"
            style={{ minHeight: "100px" }}
            aria-label="Search page title"
            value={prompt}
            onChange={(e: any) => setPrompt(e.target.value)}
          />
        </InputGroup>
        <div className="two-side_group-buttons">
          <span className="two-side_group-buttons">
            <Button
              variant="primary"
              type="button"
              className="mb-3 me-3"
              onClick={() => setPrompt("")}
            >
              Clear prompt
            </Button>
            <Button
              variant="primary"
              type="button"
              className="mb-3"
              onClick={() => setConversation([])}
            >
              Clear chat
            </Button>
          </span>

          <Button
            variant="primary"
            type="button"
            onClick={sendRequest}
            className="mb-3"
            disabled={isSend}
          >
            Send Request
          </Button>
        </div>
      </div>
      <div className="mt-3 chat_chat-gpt">
        {conversation
          .slice(0)
          .reverse()
          .map((item, index) => (
            <div key={index}>
              <p>
                {item.you && (
                  <>
                    <strong>You: </strong>
                    {item.you}
                    <br />
                  </>
                )}
                {item.gpt && (
                  <>
                    <strong>GPT: </strong>
                    {item.gpt}
                    <br />
                  </>
                )}
              </p>
              <hr />
            </div>
          ))}
      </div>
    </>
  );
};

export default OpenAIContentHelper;

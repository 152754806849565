import React from 'react';

const OpenMenuArrow = ({iconSize=15}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none">
            <path d="M2 20C2 20.5523 2.44772 21 3 21C3.55228 21 4 20.5523 4 20V4C4 3.44772 3.55228 3 3 3C2.44772 3 2 3.44772 2 4V20Z" fill="#000000"/>
            <path d="M15.2929 17.7071C14.9024 17.3166 14.9024 16.6834 15.2929 16.2929L18.5858 13L8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11L18.5858 11L15.2929 7.70711C14.9024 7.31658 14.9024 6.68342 15.2929 6.29289C15.6834 5.90237 16.3166 5.90237 16.7071 6.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L16.7071 17.7071C16.3166 18.0976 15.6834 18.0976 15.2929 17.7071Z" fill="#000000"/>
        </svg>
    );
};

export default OpenMenuArrow;
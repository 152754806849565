import { CircularProgress, IconButton, InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Close as CloseIcon } from "@mui/icons-material";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

export default function SearchInput({ value, onChange, isLoading, sx }: SearchInputProps) {
  return (
    <TextField
      color="success"
      size="small"
      InputProps={{
        startAdornment: (<InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>),
        endAdornment: (
          <>
            {isLoading && value.length > 0 ? (
              <InputAdornment position="end">
                <CircularProgress
                  color="success"
                  disableShrink
                  size={24}
                  value={40}
                  thickness={4}
                />
              </InputAdornment>
            ) : null}
            {value.length > 0 ? (<IconButton
              onMouseDown={(e: any) => {
                onChange('');
                e.preventDefault();
              }}
              size="small"
              >
              <CloseIcon /></IconButton>
            ) : null}

          </>
        )
      }}
      placeholder={'search'}
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
    />
  );

}
import React, { useEffect } from 'react';



const Home =  () => {
    return (
        <div>
            <h1>Home page</h1>
        </div>
    );
};

export default Home;
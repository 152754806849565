import React from 'react';


const Ads = () => {
    return (
        <div>
            <h2>Ads</h2>
        </div>

    );
};

export default Ads;
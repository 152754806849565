import React from 'react';
import { useAppSelector } from 'hooks/redux';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { REGISTRATION_ROUTE, LOGIN_ROUTE, HOME_ROUTE } from 'utils/consts';
import { useAuthActions } from 'hooks/authActions';



const Header = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector(state => state.auth);
    const { logOut } = useAuthActions();

    const handleLogOut = () => {
        logOut('');
        navigate(HOME_ROUTE);
    }

    return (
        <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Website promotion</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent: 'space-between'}}  >
          <Nav className="">
            <Link className='nav-link' to="/">Home</Link>
            <Link className='nav-link' to="/analytics/">Analytics</Link>
          </Nav>
          {user.isAuth ? 
              <div className='align-self-end' >
                <Button variant="outline-primary" onClick={handleLogOut}>Logout</Button>
              </div> : 
              <div className='align-self-end'>
                <Button variant="outline-primary" className='me-2' onClick={() => navigate(LOGIN_ROUTE)}>Sign in</Button>
                <Button variant="outline-info" onClick={() => navigate(REGISTRATION_ROUTE)}>Sign up</Button>
              </div> 
            }
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
};

export default Header;
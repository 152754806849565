import GeoSettings from 'components/sortElements/GeoSettings';
import React, { useState } from 'react';
import { Col, Row, ButtonGroup, Button, FloatingLabel, Form } from 'react-bootstrap';
import { useAddGeoMutation } from 'store/api/data.api';
import { Country, GeoType, Language } from 'types';





const AddGeo = ({ geos, projectId, languages, countries }: { geos: GeoType[], projectId:string, languages: Language[], countries: Country[] }) => {
    const [newGeoSettings, setNewGeoSettings] = useState<GeoType>({
        id: '',
        projectId: projectId,
        languageId: 0,
        countryId: 0,
        geoSettings: {
        },
        sortOrder: geos.length ? geos.length + 1 : 0,

    });
    const [newGeoLanguage, setNewGeoLanguage] = useState(-1);
    const [newGeoCountry, setNewGeoCountry] = useState(-1);

    const [addGeo] = useAddGeoMutation();

    const copySettings = (index: string) => {
        setNewGeoSettings({ ...geos[Number(index)], id: '', languageId: newGeoLanguage, countryId: newGeoCountry}); 
    }
    const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => { 
        setNewGeoLanguage(Number(e.target.value));
        setNewGeoSettings({ ...newGeoSettings, languageId: Number(e.target.value) });
    }
    const changeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => { 
        setNewGeoCountry(Number(e.target.value));
        setNewGeoSettings({ ...newGeoSettings, countryId: Number(e.target.value) });
    };
    const handleAddGeo = () => {
        addGeo(newGeoSettings)
        setNewGeoLanguage(0);
        setNewGeoCountry(0);
    }

    return (
        <div>
            <Row className='mt-3'>
                <Col sm={4}>
                    <FloatingLabel controlId="floatingSelect" label="lang:">
                        <Form.Select aria-label="Floating label select example" 
                            defaultValue={newGeoLanguage} 
                            onChange={changeLanguage}
                        >
                            <option value="none">none</option>
                            {
                                languages.map(lang => {
                                    return <option value={lang.id} key={lang.id}>{lang.name}</option>
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col sm={4}>
                    <FloatingLabel controlId="floatingSelect" label="country:">
                        <Form.Select aria-label="Floating label select example" 
                        defaultValue={newGeoCountry}
                            onChange={changeCountry}
                        >
                            <option value="none">none</option>
                            {
                                countries.map(country => {
                                return <option value={country.id} key={country.id}>{country.name}</option>
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col sm={4}>
                    <FloatingLabel controlId="floatingSelect" label="copy keywords and text from:">
                        <Form.Select 
                        aria-label="Floating label select example"
                        onChange={e => copySettings(e.target.value)}>
                            <option value={-1}>none</option>
                            {
                                geos.map( (geo, index) => {
                                    return (
                                        <option value={index} key={geo.id}>{ geo.languageId } - {geo.countryId}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col sm={12}>
                        <ButtonGroup className="float-end">
                            <Button onClick={handleAddGeo}>Create new Geo</Button>
                        </ButtonGroup>
                </Col>
            </Row>
        </div>
        
    );
};

export default AddGeo;
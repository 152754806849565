import React from 'react';

const CopyIcon = ({iconSize=15}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  fill="#fff" height={iconSize} width={iconSize} version="1.1"  viewBox="0 0 24 24">
            <g>
                <polygon points="11.707 3.293 10.293 4.707 17.586 12 10.293 19.293 11.707 20.707 20.414 12 11.707 3.293" fill="#fff"/>
                <polygon points="5.707 3.293 4.293 4.707 11.586 12 4.293 19.293 5.707 20.707 14.414 12 5.707 3.293" fill="#fff"/>
            </g>
        </svg>
    );
};

export default CopyIcon;
import React from 'react';

import GeoSettings from 'components/sortElements/GeoSettings'; 
import { Country, GeoType, Language } from 'types';
import { useLocation, useSearchParams } from 'react-router-dom';
import Column from 'components/sortElements/Column';
import { useSortPageActions } from 'hooks/sortPageActions';


const Geo = ({ geo, languages, countries, showSettings }: { geo: GeoType, languages: Language[], countries: Country[], showSettings: boolean }) => { 
    const { pathname } = useLocation();

    const [searchParams] = useSearchParams();
    const nastingLevels = searchParams.getAll('category')
    let titles: string[] = [];

    // console.log('geo id render',geo.id)

    nastingLevels?.forEach(nastingLevel => {
        //get titles of all levels
        titles.push(nastingLevel)
    })  

    return (
        <div className="mt-2">
            {showSettings && <GeoSettings geo={geo} languages={languages} countries={countries} />}
            <div className='columns-wrapper'>
                <Column parentPageId={0} geoId={geo.id} key={1} pathname={pathname} nastingLevels={nastingLevels} />
                {
                    nastingLevels?.map((nastingLevel, index) => {
                        return (
                            <Column parentPageId={Number(nastingLevel)} geoId={geo.id} key={nastingLevel} pathname={pathname} nastingLevels={nastingLevels} ></Column>
                        )
                    })
                }

            </div>
            
        </div>
    );
};

export default Geo;
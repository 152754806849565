import ClusterSearch from "components/searches/ClusterSearch";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  useAddPageByClusterMutation,
  useCopyPasteKeywordsMutation,
  useGetKeywordsQuery,
  useRemoveKeywordsMutation,
} from "store/api/data.api";
import AddLeft from "assets/images/icons/AddLeft";
import AddRight from "assets/images/icons/AddRight";
import AddDown from "assets/images/icons/AddDown";
import Copy from "assets/images/icons/Copy";
import Cut from "assets/images/icons/Cut";
import Paste from "assets/images/icons/Paste";
import CopyCancel from "assets/images/icons/CopyCancel";
import { useAppSelector } from "hooks/redux";
import { useSortPageActions } from "hooks/sortPageActions";

import { KeyWordState, TypePage } from "types";
import ClusterSettings from "./ClusterSettings";
import KeywordsList from "./KeywordsList";
import { useParams } from "react-router-dom";
import { SearchInput } from "components/inputs";
import { Box } from "@mui/material";

type KeyWordStateKeys = keyof KeyWordState;

interface Props {
  page: TypePage;
}

const KeysCluster: React.FC<Props> = ({ page }) => {
  const { id: projectId } = useParams();
  const { data: cluster = [] } = useGetKeywordsQuery({
    projectId: projectId,
    geoId: page.geoId,
    page_pageId: page.pageId,
  });
  const { copyState, cutState, keywordsIds, selectedGeo } = useAppSelector(
    (state) => state.sortPage
  );
  const { setCopyState, setCutState, setKeywordsIds } = useSortPageActions();

  useEffect(() => {
    // console.log(copyState, cutState, keywordsIds)
  }, [copyState, cutState]);

  const [removeKeywords] = useRemoveKeywordsMutation();
  const [addPageByCluster] = useAddPageByClusterMutation();
  const [copyPasteKeywords] = useCopyPasteKeywordsMutation();

  const [searchInput, setSearchInput] = useState("");

  const [clusterSatate, setClusterSatate] = useState<KeyWordState[]>([]);
  const [selectAllState, setSelectAllState] = useState(false);
  const [reverseSort, setReverseSort] = useState(false);

  console.log("keywords: ", cluster);

  useEffect(() => {
    if (cluster.length > 0) {
      setClusterSatate(
        cluster.map((keyword) => {
          return {
            ...keyword,
            selected: false,
          };
        })
      );
    } else {
      setClusterSatate([]);
    }
  }, [cluster]);

  const filtredKeywords = useMemo(() => {
    if (clusterSatate && clusterSatate.length > 0 && searchInput.length > 1) {
      return [...clusterSatate].filter((keyword) =>
        keyword.name
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      );
    } else {
      return clusterSatate;
    }
  }, [clusterSatate, searchInput]);

  const handleSelect = (e: React.FormEvent<HTMLInputElement>) => {
    setClusterSatate(
      clusterSatate?.map((keyword) => {
        if (keyword.id + "" === e.currentTarget.id) {
          keyword.selected = !keyword.selected;
        }
        return keyword;
      })
    );
  };

  const handleAllSelect = (e: React.FormEvent<HTMLInputElement> | boolean) => {
    filtredKeywords.forEach((filtredKeyword) => {
      setClusterSatate(
        clusterSatate?.map((keyword) => {
          if (keyword.id === filtredKeyword.id) {
            if (typeof e === "boolean") {
              keyword.selected = e;
            } else {
              keyword.selected = e.currentTarget.checked;
            }
          }
          return keyword;
        })
      );
    });
    if (typeof e === "boolean") {
      setSelectAllState(e);
    } else {
      setSelectAllState(e.currentTarget.checked);
    }
  };

  const handleRemoveKeywords = () => {
    removeKeywords(
      clusterSatate
        .filter((keyword) => keyword.selected)
        .map((keyword) => keyword.id)
    );
    setSelectAllState(false);
    setSearchInput('');
  };

  const sortBy = (key: KeyWordStateKeys) => {
    if (reverseSort) {
      setClusterSatate(
        clusterSatate.sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    } else {
      setClusterSatate(
        clusterSatate.sort((a, b) => (a[key] > b[key] ? -1 : 1))
      );
    }
    setReverseSort(!reverseSort);
  };

  const numberSelected = filtredKeywords.filter(
    (keyword) => keyword.selected
  ).length;

  const handleCreatePageByKeywords = (where: string) => {
    console.log("create page by keywords", where);
    const keywordsIds = filtredKeywords
      .filter((keyword) => keyword.selected)
      .map((keyword) => keyword.id);
    console.log("data", keywordsIds);
    addPageByCluster({ ...page, keywordsIds, projectId, variant: where });
    setSelectAllState(false);
    setSearchInput('');
  };

  const handleCopy = () => {
    // console.log("copy")
    setCopyState(true);
    setCutState(false);
    setKeywordsIds(
      filtredKeywords
        .filter((keyword) => keyword.selected)
        .map((keyword) => keyword.id)
    );
    handleAllSelect(false);
  };
  const handleCut = () => {
    // console.log("cut")
    setCopyState(false);
    setCutState(true);
    setKeywordsIds(
      filtredKeywords
        .filter((keyword) => keyword.selected)
        .map((keyword) => keyword.id)
    );
    handleAllSelect(false);
  };
  const handlePaste = () => {
    setCopyState(false);
    setCutState(false);
    copyPasteKeywords({
      keywordsIds,
      page_pageId: page.pageId,
      projectId: selectedGeo[projectId!].projectId,
      geoId: selectedGeo[projectId!].id,
      languageId: selectedGeo[projectId!].languageId,
      countryId: selectedGeo[projectId!].countryId,
      variant: copyState ? "copy" : "cut",
    });
    handleAllSelect(false);
  };
  const clearCopystate = () => {
    setCopyState(false);
    setCutState(false);
    handleAllSelect(false);
  };
  return (
    <div>
      <div>

        <Row className="my-3">
          <Col sm={8} >
            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2, alignItems: 'center', gap: '10px'}} >
            <SearchInput value={searchInput} onChange={setSearchInput } isLoading={false} sx={{width: '80%'}}/> 
            <span>{filtredKeywords.length} keys</span>
            </Box>
           
          </Col>
          <Col sm={4} className="text-end">
            <ClusterSettings />
          </Col>
          <Col sm={12} className="d-inline-flex">
            <Button
              className="btn-primary btn-sm me-2"
              disabled={numberSelected === 0 || copyState || cutState}
              onClick={() => handleCreatePageByKeywords("left")}
            >
              <AddLeft iconSize={20} />
            </Button>
            <Button
              className="btn-primary btn-sm me-2"
              disabled={numberSelected === 0 || copyState || cutState}
              onClick={() => handleCreatePageByKeywords("down")}
            >
              <AddDown iconSize={20} />
            </Button>
            <Button
              className="btn-primary btn-sm me-2"
              disabled={numberSelected === 0 || copyState || cutState}
              onClick={() => handleCreatePageByKeywords("right")}
            >
              <AddRight iconSize={20} />
            </Button>
            <Button
              className="btn-primary btn-sm me-2"
              disabled={numberSelected === 0 || copyState || cutState}
              onClick={handleCopy}
            >
              <Copy iconSize={20} />
            </Button>
            <Button
              className="btn-primary btn-sm me-2"
              disabled={numberSelected === 0 || copyState || cutState}
              onClick={handleCut}
            >
              <Cut iconSize={20} />
            </Button>
            <Button
              title="paste keys"
              className="btn-primary btn-sm me-2"
              disabled={copyState || cutState ? false : true}
              onClick={handlePaste}
            >
              <Paste iconSize={20} />
            </Button>
            <Button
              title="clear buffer"
              className="btn-primary btn-sm me-2"
              disabled={copyState || cutState ? false : true}
              onClick={clearCopystate}
            >
              <CopyCancel iconSize={20} />
            </Button>
            <Button
              title="remove keys"
              className="btn-danger btn-sm me-2"
              onClick={handleRemoveKeywords}
              disabled={numberSelected === 0}
            >
              -
            </Button>
          </Col>
        </Row>
      </div>
      {cluster && (
        <KeywordsList
          filtredKeywords={filtredKeywords}
          handleSelect={handleSelect}
          handleAllSelect={handleAllSelect}
          sortBy={sortBy}
          selectAllState={selectAllState}
        />
      )}
    </div>
  );
};

export default KeysCluster;

import React, { useEffect } from "react";

import "assets/styles/geos.scss";

import ProjectHeader from "components/project/ProjectHeader";
import { Tab, Tabs } from "react-bootstrap";
import {
  useGetCountriesQuery,
  useGetGeosQuery,
  useGetLanguagesQuery,
} from "store/api/data.api";
import { useParams } from "react-router-dom";
import Geo from "components/sortElements/Geo";
import { GeoType } from "types";
import SettingsIcon from "assets/images/icons/SettingsIcon";
import AddGeo from "components/sortElements/AddGeo";
import { useAppSelector } from "hooks/redux";
import { useSortPageActions } from "hooks/sortPageActions";

const SortPage = () => {
  const { id = "" } = useParams();
  const { data: geos = [], isSuccess } = useGetGeosQuery(id);
  const { setSelectedGeo, setCurrentProject } = useSortPageActions();

  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

  const [showSettings, setShowSettings] = React.useState(false);

  const { selectedGeo } = useAppSelector((state) => state.sortPage);

  const handleSelectGeo = (eventKey: string) => {
    if (eventKey === "add lang") {
      setSelectedGeo({ [id]: "add lang" });
    }
    setSelectedGeo({
      [id]: geos.find((geo) => geo.id === eventKey) as GeoType,
    });
  };

  if (!languages || languages.length === 0) {
    return <div>Languages wasn't receive...</div>;
  }
  if (!countries || countries.length === 0) {
    return <div>Countries wasn't receive...</div>;
  }

  return (
    <div className="">
      <ProjectHeader />
      <div className="geo mt-2">
        <button
          className="settings-btn"
          onClick={() => setShowSettings(!showSettings)}
        >
          <SettingsIcon iconSize={20} />
        </button>
        {isSuccess && isLanguagesSuccess && isCountriesSuccess && (
          <Tabs
            key="geos"
            mountOnEnter
            unmountOnExit
            activeKey={selectedGeo[id] ? selectedGeo[id].id : "add lang"}
            transition={false}
            id={id}
            onSelect={(eventKey) => handleSelectGeo(eventKey as string)}
          >
            {geos &&
              geos?.map((geo: GeoType) => {
                return (
                  <Tab
                    eventKey={geo.id}
                    title={
                      languages!.find((lang) => lang.id === geo.languageId)!
                        .code +
                      " - " +
                      countries!
                        .find((country) => country.id === geo.countryId)!
                        .code.toLocaleLowerCase()
                    }
                    key={geo.id}
                  >
                    <Geo
                      geo={geo}
                      languages={languages}
                      countries={countries}
                      showSettings={showSettings}
                    />
                  </Tab>
                );
              })}
            <Tab eventKey={"add lang"} title="+" key={"0"}>
              {isSuccess && isLanguagesSuccess && isCountriesSuccess && (
                <AddGeo
                  geos={geos}
                  projectId={id}
                  languages={languages}
                  countries={countries}
                />
              )}
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default SortPage;

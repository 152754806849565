import React from 'react';

import { Col, Row, Form } from 'react-bootstrap';


import { useState } from 'react';


const Overview = () => {


    const [datatype, setDataType] = useState('day')
    console.log(datatype)

    return (
        <div>
            <h2>Owerview</h2>
            <Row>
                <Col sm={6}>
                    bars
                </Col>
                <Col sm={2}>
                    <Form.Select onChange={(e)=> setDataType(e.target.value)}>
                        <option value="day" >Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                    </Form.Select>
                </Col>
            </Row>
        </div>
    );
};



export default Overview;
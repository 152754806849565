import React from 'react';


const Analytics = () => {
    return (
        <div>
            <h2>Google analytics</h2>
        </div>
    );
};

export default Analytics;
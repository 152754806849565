import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/esm/Modal';
import { useParams } from 'react-router-dom';
import { useAddPageMutation, useUpdatePageMutation } from 'store/api/data.api';
import {EnumPageType, TypePage} from 'types'


const initPage:TypePage = {
    id: 0,
    pageId: 0,
    geoId:'',
    parentPageId: 0,
    name: '',
    pageType: 0,
    sortOrder: 0
}


const PageNameForm = ({ showForm, setShowForm, parentPageId, geoId, page = initPage }: { showForm: boolean, setShowForm: Function, parentPageId: number, geoId: string, page?: TypePage }) => {  
    const [newPage, setNewPage] = useState({ ...initPage, geoId, parentPageId, id: page.id, name: page.name, pageType: page.pageType, sortOrder: page.sortOrder });
    const pageTypes = Object.keys(EnumPageType).filter((type) => isNaN(Number(type)))
    const [addPage] = useAddPageMutation();
    const [updatePage] = useUpdatePageMutation();
    const {id:projectId} = useParams()


    const handleSubmit = (event:any) => {
        event.preventDefault();
        setShowForm(false)
        page.id ? updatePage(newPage) : addPage({ ...newPage, projectId: projectId })
    }

    const handleChange = (e: any) => {
        console.log([e.target.name], e.target.value)
        setNewPage({...newPage, [e.target.name]: e.target.value})
    }    
    return (
        <Modal
        size="sm"
        show={showForm}
        onHide={() => setShowForm(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm text-center">
                    {page.id ? 'Edit Page' : 'Add Page'}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form noValidate validated={false} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Page name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="new"
                        defaultValue={page.name}
                        name='name'
                        onChange={handleChange}

                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Select
                        aria-label="Default select example"
                        defaultValue={page['pageType']}
                        name='pageType'
                        onChange={handleChange}
                        >
                            {
                               pageTypes.map((types:string, index:number) => {
                                    return (
                                        <option value={index} key={types}>{types}</option>
                                    )
                               })
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Button type="submit" className='float-end'>save</Button>
            </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PageNameForm;
import React from 'react';


import { Col, Row, Form } from 'react-bootstrap';
import { useState } from 'react';




const Analytics = () => {

    const [datatype, setDataType] = useState('day');


    return (
        <div>
            <h1>Analitics page</h1>
            <Row>
                <Col sm={6}>
                    <h2>Charts</h2>
                </Col>
                <Col sm={2}>
                    <Form.Select onChange={(e)=> setDataType(e.target.value)}>
                        <option value="day" >Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                    </Form.Select>
                </Col>
            </Row>
        </div>
    );
};

export default Analytics;
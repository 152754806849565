import React from 'react';


const Organic = () => {
    return (
        <div>
            <h2>Organic search</h2>
        </div>
    );
};

export default Organic;
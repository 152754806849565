import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Project,
  GeoType,
  Language,
  Country,
  TypePage,
  KeyWord,
  IContent,
} from "types";

import uuid from "react-uuid";
import { API_URL } from "utils/consts";

const initialProjectSettings = {
  useChatGPT: false,
  chatGPTApiKey: "",
  useAhrefs: false,
  ahrefsToken: "",
  ahrefsApiKey: "",
};

export const projectsApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/api",
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  keepUnusedDataFor: 1,
  tagTypes: [
    "Projects",
    "Project",
    "Geo",
    "PageNesting",
    "Keywords",
    "Content",
  ],
  endpoints: (build) => ({
    getProjects: build.query<Project[], void>({
      query: () => ({
        url: `/projects`,
      }),
      providesTags: ["Projects"],
    }),
    addProject: build.mutation<Project, string>({
      query: (name) => ({
        url: `/projects`,
        method: "POST",
        body: {
          id: uuid(),
          name,
          projectSettings: initialProjectSettings,
        },
      }),
      invalidatesTags: ["Projects"],
    }),
    updateProject: build.mutation<void, any>({
      query: (project) => ({
        url: `/projects/${project.id}`,
        method: "PUT",
        body: {
          id: project.id,
          name: project.newName,
          projectSettings: project.projectSettings,
        },
      }),
      invalidatesTags: ["Projects", "Project"],
    }),
    removeProject: build.mutation<Project, string>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects", "Project"],
    }),
    getProject: build.query<Project, string>({
      query: (id) => ({
        url: `/projects/${id}`,
      }),
      providesTags: ["Project"],
    }),
    updateProjectSettings: build.mutation<void, Project>({
      query: (project) => ({
        url: `/projects/${project.id}`,
        method: "PUT",
        body: {
          ...project,
        },
      }),
      invalidatesTags: ["Project"],
    }),
    getGeos: build.query<GeoType[], string>({
      query: (projectId) => ({
        url: `/geo?projectId=${projectId}`,
      }),
      providesTags: ["Geo"],
    }),
    addGeo: build.mutation<void, GeoType>({
      query: (body) => ({
        url: `/geo/`,
        method: "POST",
        body: {
          ...body,
          id: uuid(),
        },
      }),
      invalidatesTags: ["Geo"],
    }),
    updateGeo: build.mutation<void, GeoType>({
      query: (body) => ({
        url: `/geo/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Geo"],
    }),
    removeGeo: build.mutation<void, string>({
      query: (id) => ({
        url: `/geo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Geo"],
    }),
    getLanguages: build.query<Language[], void>({
      query: () => ({
        url: `/geo/languges/`,
      }),
    }),
    getCountries: build.query<Country[], void>({
      query: () => ({
        url: `/geo/countries/`,
      }),
    }),
    getPages: build.query<TypePage[], any>({
      query: ({ geoId, parentPageId }) => ({
        url: `/pages?geoId=${geoId}&parentPageId=${parentPageId}`,
      }),
      providesTags: ["PageNesting"],
    }),
    getParentPageByIdAndGeo: build.query<TypePage, any>({
      query: ({ parentPageId, geoId }) => ({
        url: `/pages/parent-page?parentPageId=${parentPageId}&geoId=${geoId}`,
      }),
    }),
    addPage: build.mutation<void, any>({
      query: (body) => ({
        url: `/pages`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PageNesting"],
    }),
    addPageByCluster: build.mutation<void, any>({
      query: (body) => ({
        url: `/pages/create-page-by-cluster`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PageNesting", "Keywords"],
    }),
    updatePage: build.mutation<void, TypePage>({
      query: (body) => ({
        url: `/pages/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PageNesting"],
    }),
    removePage: build.mutation<void, any>({
      query: ({ pageId, projectId }) => ({
        url: `/pages?pageId=${pageId}&projectId=${projectId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PageNesting"],
    }),
    getKeywords: build.query<KeyWord[], any>({
      query: ({ geoId, page_pageId, projectId }) => ({
        url: `/keywords?geoId=${geoId}&page_pageId=${page_pageId}&projectId=${projectId}`,
      }),
      providesTags: ["Keywords"],
    }),
    addKeywords: build.mutation<void, any>({
      query: (body) => ({
        url: `/multiple-keywords/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ["Keywords"],
    }),
    addKeyword: build.mutation<void, KeyWord>({
      query: (body) => ({
        url: `/keywords`,
        method: "POST",
        body: {
          ...body,
        },
      }),
      invalidatesTags: ["Keywords"],
    }),
    removeKeyword: build.mutation<void, number>({
      query: (id) => ({
        url: `/keywords/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Keywords"],
    }),
    removeKeywords: build.mutation<void, number[]>({
      query: (keywords) => ({
        url: `/multiple-keywords/delete`,
        method: "POST",
        body: {
          keywords,
        },
      }),
      invalidatesTags: ["Keywords"],
    }),
    copyPasteKeywords: build.mutation<void, any>({
      query: (body) => ({
        url: `/multiple-keywords/copy-paste`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Keywords"],
    }),
    getContent: build.query<IContent, any>({
      query: ({ geoId, page_pageId }) => ({
        url: `/content?geoId=${geoId}&page_pageId=${page_pageId}`,
      }),
      providesTags: ["Content"],
    }),
    saveContent: build.mutation<void, IContent>({
      query: (body) => ({
        url: `/content/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Content"],
    }),
    updateContent: build.mutation<void, IContent>({
      query: (body) => ({
        url: `/content/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Content"],
    }),
    deleteContent: build.mutation<void, IContent>({
      query: (body) => ({
        url: `/content/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Content"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useRemoveProjectMutation,
  useUpdateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectSettingsMutation,
  useGetGeosQuery,
  useAddGeoMutation,
  useUpdateGeoMutation,
  useRemoveGeoMutation,
  useGetLanguagesQuery,
  useGetCountriesQuery,
  useGetPagesQuery,
  useGetParentPageByIdAndGeoQuery,
  useAddPageMutation,
  useAddPageByClusterMutation,
  useUpdatePageMutation,
  useRemovePageMutation,
  useGetKeywordsQuery,
  useAddKeywordsMutation,
  useAddKeywordMutation,
  useRemoveKeywordMutation,
  useRemoveKeywordsMutation,
  useCopyPasteKeywordsMutation,
  useGetContentQuery,
  useUpdateContentMutation,
  useDeleteContentMutation,
  useSaveContentMutation,
} = projectsApi;

export const meta_title = 'Provide catchy blog post meta-title, 60 characters or less and relating to the list of SEO keywords below: ';
export const meta_description = 'Provide catchy blog meta-description,  150 characters or less and relating to the list of SEO keywords below: ';
export const title = 'Provide catchy blog post title, 100 characters or less and relating to the list of SEO keywords below: ';
export const content = 'write a catchy blog post description that is 50 to 100 characters and relating to the list of SEO keywords below: ';

export default {
    meta_title,
    meta_description,
    title,
    content,
}
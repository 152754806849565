import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetKeywordsQuery } from "store/api/data.api";
import { IContent, KeyWord } from "types";

interface Props {
  cluster: KeyWord[];
  contentForm: IContent;
}

interface IcontentStat extends KeyWord {
  mTitle: boolean;
  mDesc: boolean;
  h1: boolean;
  h2: number;
  occurance: number;
  percentOfOccurences: number;
}

type KeyWordStateKeys = keyof IcontentStat;

const ContentKeywordList: React.FC<Props> = ({ contentForm, cluster }) => {
  const { id: projectId, geoId, pageId } = useParams();
  const [clusterSatate, setClusterSatate] = useState<IcontentStat[]>([
    ...cluster.map((keyword) => ({
      ...keyword,
      mTitle: false,
      mDesc: false,
      h1: false,
      h2: 0,
      occurance: 0,
      percentOfOccurences: 0,
    })),
  ]);

  const [reverseSort, setReverseSort] = useState(false);
  const sortBy = (key: KeyWordStateKeys) => {
    console.log(reverseSort);
    if (reverseSort) {
      setClusterSatate([
        ...clusterSatate.sort((a, b) => (a[key] > b[key] ? 1 : -1)),
      ]);
    } else {
      setClusterSatate([
        ...clusterSatate.sort((a, b) => (a[key] > b[key] ? 1 : -1)),
      ]);
    }
    setReverseSort((prev) => !prev);
  };
  // console.log(contentForm);
  const regex = /(<([^>]+)>)/gi;
  const text = contentForm.content
    .map((item) => item.title! + item.text!)
    .join(" ")
    .replace(regex, " ")
    .replace("&nbsp;", " ")
    .replace(/\s\s+/g, " ")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s/g, " ")
    .trim()
    .toLowerCase();
  const textLength = text.split(" ").length;
  const m_Title: string = contentForm.meta_title.toLowerCase();
  const m_Desc: string = contentForm.meta_description.toLowerCase();
  const h1: string = contentForm.title.toLowerCase();
  const h2: string[] = [];
  contentForm.content.forEach((item) => {
    if (item.title) {
      h2.push(item.title.toLowerCase());
    }
    if (item.text) {
      item.text
        .split("<h2>")
        .slice(1)
        .map((item) => item.split("</h2>")[0])
        .forEach((item) => {
          h2.push(item.toLowerCase());
        });
    }
  });

  useEffect(() => {
    const newClusterSatate = clusterSatate.map((keyword) => ({
      ...keyword,
      mTitle: m_Title.includes(keyword.name.toLowerCase()),
      mDesc: m_Desc.includes(keyword.name.toLowerCase()),
      h1: h1.includes(keyword.name.toLowerCase()),
      h2: h2.filter((item) => item.includes(keyword.name.toLowerCase())).length,
      occurance: text.split(keyword.name.toLowerCase()).length - 1,
      percentOfOccurences:
        Math.round(
          (((text.split(keyword.name.toLowerCase()).length - 1) * 100) /
            text.split(" ").length) *
            100
        ) / 100,
    }));
    setClusterSatate(newClusterSatate);
  }, [contentForm]);

  // const totalValue = filtredKeywords.reduce((acc, keyword) => acc + keyword.volume, 0)
  // const averegeKD = filtredKeywords.length > 0 ? Math.round(filtredKeywords.reduce((acc, keyword) => acc + keyword.kd, 0) / filtredKeywords.length) : 0
  // const averegeNumberOfOccurences = filtredKeywords.length > 0 ? (filtredKeywords.reduce((acc, keyword) => acc + keyword.numberOfOccurences, 0) / filtredKeywords.length).toFixed(2) : 0
  // const averegePercentOfOccurences = filtredKeywords.length > 0 ? Math.round(filtredKeywords.reduce((acc, keyword) => acc + keyword.percentOfOccurences, 0) / filtredKeywords.length) : 0

  return (
    <table className="table table-striped table-bordered cluster-table">
      <thead>
        <tr>
          <th>#</th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("name")}
            >
              Keyword
            </span>
          </th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("h1")}
            >
              m_title
            </span>
          </th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("h1")}
            >
              m_desc
            </span>
          </th>

          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("h1")}
            >
              title
            </span>
          </th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("h2")}
            >
              h2
            </span>
          </th>

          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("occurance")}
            >
              occurance
            </span>
          </th>
          <th>
            <span
              className="sort-btn"
              title="sort"
              onClick={() => sortBy("percentOfOccurences")}
            >
              %
            </span>
          </th>
          {/* <th><span className='sort-btn' title='sort' onClick={()=> sortBy('volume')} >Volume</span></th>
                            <th><span className='sort-btn' title='sort' onClick={()=> sortBy('kd')} >KD</span></th>
                            <th><span className='sort-btn' title='sort' onClick={() => sortBy('numberOfOccurences')} >Occurences</span></th>
                            <th><span className='sort-btn' title='sort' onClick={() => sortBy('percentOfOccurences')} >Percent</span></th> */}
        </tr>
      </thead>
      <tbody>
        {clusterSatate.map((keyword, index) => {
          return (
            <tr key={keyword.id}>
              <td>{index}</td>
              <td>{keyword.name}</td>
              <td
                className={"text-center ".concat(
                  keyword.mTitle ? "text-success" : "text-danger"
                )}
              >
                {keyword.mTitle ? "true" : "false"}
              </td>
              <td
                className={"text-center ".concat(
                  keyword.mDesc ? "text-success" : "text-danger"
                )}
              >
                {keyword.mDesc ? "true" : "false"}
              </td>
              <td
                className={"text-center ".concat(
                  keyword.h1 ? "text-success" : "text-danger"
                )}
              >
                {keyword.h1 ? "true" : "false"}
              </td>
              <td>{keyword.h2}</td>
              <td>{keyword.occurance}</td>
              <td>{keyword.percentOfOccurences}</td>
              {/* <td>{keyword.volume}</td>
                                    <td>{keyword.kd}</td>
                                    <td>{keyword.numberOfOccurences}</td>
                                    <td>{keyword.percentOfOccurences}</td> */}
            </tr>
          );
        })}
      </tbody>
      {/* <tfoot>
        <tr>
          <td>
            <span className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectAllState}
                onChange={handleAllSelect}
              />
            </span>
          </td>
          <td>Total:</td>
          <td>{totalValue}</td>
                            <td>{averegeKD}</td>
                            <td>{averegeNumberOfOccurences}</td>
                            <td>{averegePercentOfOccurences}</td>
        </tr>
      </tfoot> */}
    </table>
  );
};

export default ContentKeywordList;

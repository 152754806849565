import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useUpdateProjectSettingsMutation } from "store/api/data.api";

const ProjectHeaderSettings = ({ project }: any) => {
  const [settings, setSettings] = useState(project.projectSettings);
  const [modifedSettings, setModifedSettings] = useState(false);
  const [updateProjectSettings] = useUpdateProjectSettingsMutation();

  useEffect(() => {
    setSettings(project.projectSettings);
  }, [project]);

  const handleChange = (e: any, key: string) => {
    setSettings({
      ...settings,
      [key]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
      modifed: true,
    });
    setModifedSettings(true);
  };
  const handleSubmit = () => {
    updateProjectSettings({ ...project, projectSettings: settings });
    setModifedSettings(false);
  };

  return (
    <div className="project-settings">
      <Form className="container-fluid">
        <Row className="mb-3 row-heght-fix">
          <Form.Group as={Col} sm={2} controlId="useChatGPT">
            <Form.Check
              className="d-inline-block"
              type="switch"
              id="custom-switch"
              label="Use ChatGPT?"
              defaultChecked={settings.useChatGPT ? true : false}
              onChange={(e) => handleChange(e, "useChatGPT")}
            />
          </Form.Group>
          <Col sm={5}>
            <Form.Group
              as={Row}
              controlId="chatGPTApiKey"
              className={"".concat(settings.useChatGPT ? "" : " hide")}
            >
              <Form.Label column sm={3}>
                ChatGPT Key
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="ChatGPT Key"
                  value={settings.chatGPTApiKey}
                  onChange={(e) => handleChange(e, "chatGPTApiKey")}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 row-heght-fix">
          <Form.Group as={Col} sm={2} controlId="exampleForm.ControlInput1">
            <Form.Check
              type="switch"
              id="custom-switch-2"
              label="Use Ahrefs?"
              defaultChecked={settings.useAhrefs ? true : false}
              onChange={(e) => handleChange(e, "useAhrefs")}
            />
          </Form.Group>
          <Col sm={5}>
            <Form.Group
              as={Row}
              controlId="ahrefsToken"
              className={"".concat(settings.useAhrefs ? "" : " hide")}
            >
              <Form.Label column sm={3}>
                Token
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="ChatGPT Key"
                  value={settings.ahrefsToken}
                  onChange={(e) => handleChange(e, "ahrefsToken")}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={5}>
            <Form.Group
              as={Row}
              controlId="ahrefsApiKey"
              className={"".concat(settings.useAhrefs ? "" : " hide")}
            >
              <Form.Label column sm={3}>
                Api key
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="ahrefs Api Key"
                  value={settings.ahrefsApiKey}
                  onChange={(e) => handleChange(e, "ahrefsApiKey")}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 row-heght-fix">
          <Form.Group as={Col} sm={2} controlId="googleStats">
            <Form.Check
              type="switch"
              id="googleStats"
              label="Use Google stats?"
              defaultChecked={settings.googleStats ? true : false}
              onChange={(e) => handleChange(e, "googleStats")}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 row-heght-fix justify-content-end">
          <Col sm={2} className="text-end">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!modifedSettings}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ProjectHeaderSettings;

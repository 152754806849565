import { bindActionCreators } from "@reduxjs/toolkit"; 
import { useDispatch } from "react-redux";
import { analyticsLayoutActions } from "store/analyticsLayout/analyticsLayout.slice";


const actions = {
    ...analyticsLayoutActions,
}

export const useAnaliticsLayoutActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(actions, dispatch);

}
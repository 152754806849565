import React from 'react';

const ProductIcon = ({iconSize=15}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"  height={iconSize} width={iconSize} viewBox="0 0 24 24" version="1.1">

            <title/>

            <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">

            <g id="导航图标" transform="translate(-325.000000, -80.000000)">

            <g id="编组" transform="translate(325.000000, 80.000000)">

            <polygon fill="#FFFFFF" fillOpacity="0.01" fillRule="nonzero" id="路径" points="24 0 0 0 0 24 24 24"/>

            <polygon id="路径" points="22 7 12 2 2 7 2 17 12 22 22 17" stroke="#212121" strokeLinejoin="round" strokeWidth="1.5"/>

            <line id="路径" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="2" x2="12" y1="7" y2="12"/>

            <line id="路径" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="12" x2="12" y1="22" y2="12"/>

            <line id="路径" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="22" x2="12" y1="7" y2="12"/>

            <line id="路径" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="17" x2="7" y1="4.5" y2="9.5"/>

            </g>

            </g>

            </g>

            </svg>
    </>
    );
};

export default ProductIcon;
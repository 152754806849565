import React from "react";
import { Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/styles/main.scss";

import NotFound from "pages/NotFound";
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";
import Analytics from "pages/Analytics";
import Overview from "pages/analytics/Overview";
import Home from "pages/Home";
import SortPage from "pages/analytics/SortPage";
import Organic from "pages/analytics/Organic";
import Ads from "pages/analytics/Ads";
import GoogleAnalytics from "pages/analytics/GoogleAnalytics";
import AnalyticsLayout from "layout/AnalyticsLayout/AnalyticsLayout";
import Content from "pages/analytics/Content";
import Auth from "pages/Auth";

function App() {
  const isAuthenticated = true;
  return (
    <>
      <Header />
      <div className="content container-fluid">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/registration" element={<Auth />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/analytics" element={<AnalyticsLayout />}>
            <Route index element={<Analytics />} />
            <Route path=":id/overview" element={<Overview />} />
            <Route path=":id/sort-page" element={<SortPage />} />
            <Route path=":id/organic" element={<Organic />} />
            <Route path=":id/google-ads" element={<Ads />} />
            <Route path=":id/google-analytics" element={<GoogleAnalytics />} />
            <Route
              path=":id/content/:geoId/:page_pageId"
              element={<Content />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {/* <Footer/> */}
    </>
  );
}

export default App;

import TextComponent from "components/content/TextComponent";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import "assets/styles/content-page.scss";
import {
  useGetContentQuery,
  useGetKeywordsQuery,
  useSaveContentMutation,
  useUpdateContentMutation,
} from "store/api/data.api";
import { IContent } from "types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import uuid from "react-uuid";
import ContentKeywordList from "components/content/ContentKeywordList";
import OpenAIContentHelper from "components/content/OpenAIContentHelper";
import { useEffect } from "react";

const schema = yup
  .object({
    id: yup.number(),
    meta_title: yup.string(),
    meta_description: yup.string(),
    meta_keywords: yup.string(),
    title: yup.string(),
    projectId: yup.string().required(),
    geoId: yup.string().required(),
    page_pageId: yup.number().required(),
    content: yup.array().of(
      yup.object({
        id: yup.string(),
        contentType: yup.string(),
        title: yup.string(),
        value: yup.string(),
        order: yup.number(),
        products: yup.array().of(yup.number()),
      })
    ),
  })
  .required();

const Content = () => {
  const { id: projectId, geoId, page_pageId } = useParams();
  const initContent: IContent = {
    id: 0,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    title: "",
    projectId: projectId!,
    geoId: geoId!,
    page_pageId: page_pageId ? +page_pageId : 0,
    content: [],
  };
  const { data, isSuccess } = useGetContentQuery({
    geoId,
    page_pageId,
  });

  const { data: cluster = [] } = useGetKeywordsQuery({
    geoId,
    page_pageId: page_pageId,
  });

  const [updateContent] = useUpdateContentMutation();
  const [saveContent] = useSaveContentMutation();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: initContent,
    resolver: yupResolver(schema),
  });

  const {
    fields: content,
    append: addContent,
    remove: removeContent,
    update: upadateArray,
  } = useFieldArray({
    control,
    name: "content",
  });

  useEffect(() => {
    setValue(
      "meta_keywords",
      cluster ? cluster.map((item) => item.name).join(", ") : ""
    );
  }, [cluster]);
  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [isSuccess]);

  const contentForm = watch();
  // console.log(contentForm);

  const onSubmit = (data: IContent) => {
    if (data.id > 0) {
      updateContent(data);
    } else {
      saveContent(data);
    }
  };

  return (
    <Row>
      <Col sm={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm={12} className="mb-3 text-end">
              <Button variant="primary" type="submit" disabled={!isDirty}>
                save
              </Button>
            </Col>
            <h3>We sould add all content types, geo tabs </h3>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Meta title</InputGroup.Text>
              <Controller
                control={control}
                name={`meta_title`}
                render={({
                  field: { onChange, ref, value },
                  fieldState,
                  formState,
                }) => (
                  <Form.Control
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    aria-label="meta_title"
                  />
                )}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                Meta description
              </InputGroup.Text>
              <Controller
                control={control}
                name={`meta_description`}
                render={({
                  field: { onChange, ref, value },
                  fieldState,
                  formState,
                }) => (
                  <Form.Control
                    aria-label="meta_description"
                    ref={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Meta keywords</InputGroup.Text>
              <Controller
                control={control}
                name={`meta_keywords`}
                render={({
                  field: { onChange, ref, value },
                  fieldState,
                  formState,
                }) => (
                  <Form.Control
                    aria-label="meta_keywords"
                    ref={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Tile (h1)</InputGroup.Text>
              <Controller
                control={control}
                name={`title`}
                render={({
                  field: { onChange, ref, value },
                  fieldState,
                  formState,
                }) => (
                  <Form.Control
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    aria-label="title"
                  />
                )}
              />
            </InputGroup>
            <Col sm={12} className="content-page_content my-3">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr key="head">
                    <th className="text-center">Content</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {content.map((cont:any, index) => (
                    <tr key={cont.id}>
                      <td>
                        {cont.contentType === "text" && (
                          <TextComponent
                            key={cont.id}
                            value={cont}
                            control={control}
                            contentIndex={index}
                          />
                        )}
                      </td>
                      <td className="text-center align-middle">
                        <Button
                          key={cont.id}
                          variant="danger"
                          onClick={() => {
                            removeContent(index);
                          }}
                        >
                          -
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            <Col sm={12} className="text-end">
              <ButtonGroup>
                <Button
                  variant="primary"
                  id={"text"}
                  onClick={() => {
                    addContent({
                      id: uuid(),
                      contentType: "text",
                      title: "",
                      text: "",
                      order: 0,
                      products: [],
                    });
                  }}
                >
                  Text
                </Button>
                <Button variant="success">Product</Button>
                <Button variant="primary">Product&Description</Button>
              </ButtonGroup>
            </Col>
          </Row>
          {JSON.stringify(errors)}
        </form>
      </Col>

      <Col sm={4}>
        <div className="right-side-panel">
          {cluster && cluster.length > 0 && (
            <ContentKeywordList cluster={cluster} contentForm={contentForm} />
          )}
          <OpenAIContentHelper
            cluster={cluster}
            setValue={setValue}
            upadateArray={upadateArray}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Content;
